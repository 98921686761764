document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll(".double-range-slider").forEach((component) => {
    const getLowSlider = () => component.querySelector('input[name="low"]');
    const getHighSlider = () => component.querySelector('input[name="high"]');
    const getLeftHandle = () => component.querySelector("[handle-left]");
    const getRightHandle = () => component.querySelector("[handle-right]");
    const getLeftRange = () => component.querySelector("[range-left]");
    const getCenterRange = () => component.querySelector("[range-center]");
    const getRightRange = () => component.querySelector("[range-right]");
    const getLeftLabel = () => component.querySelector("[label-left]");
    const getRightLabel = () => component.querySelector("[label-right]");

    component.addEventListener("set-value", (event) => {
      const { low, high } = event.detail;
      getLowSlider().value = low;
      getLowSlider().dispatchEvent(new Event("input"));
      getHighSlider().value = high;
      getHighSlider().dispatchEvent(new Event("input"));
    });

    component.addEventListener("set-labels", (event) => {
      const { low, high } = event.detail;
      getLeftLabel().textContent = low;
      getRightLabel().textContent = high;
    });

    getLowSlider().addEventListener("input", (event) => {
      const highSlider = getHighSlider();
      event.target.value = Math.min(
        event.target.valueAsNumber,
        highSlider.valueAsNumber - 1
      );

      var value =
        (100 /
          (parseInt(event.target.max || 100) -
            parseInt(event.target.min || 0))) *
          parseInt(event.target.value) -
        (100 /
          (parseInt(event.target.max || 100) -
            parseInt(event.target.min || 0))) *
          parseInt(event.target.min || 0);

      getLeftRange().style.width = value + "%";
      getCenterRange().style.left = value + "%";
      getLeftHandle().style.left = value + "%";
      getLeftLabel().style.left = `calc(${value}% + 30px)`;

      component.dispatchEvent(
        new CustomEvent("range-change", {
          detail: {
            low: parseInt(event.target.value),
            high: highSlider.valueAsNumber,
          },
        })
      );
    });

    getHighSlider().addEventListener("input", (event) => {
      const lowSlider = getLowSlider();
      event.target.value = Math.max(
        event.target.valueAsNumber,
        lowSlider.valueAsNumber + 1
      );

      var value =
        (100 /
          (parseInt(event.target.max || 100) -
            parseInt(event.target.min || 0))) *
          parseInt(event.target.value) -
        (100 /
          (parseInt(event.target.max || 100) -
            parseInt(event.target.min || 0))) *
          parseInt(event.target.min || 0);

      getRightRange().style.width = 100 - value + "%";
      getCenterRange().style.right = 100 - value + "%";
      getRightHandle().style.left = value + "%";
      getRightLabel().style.left = `${value}%`;

      component.dispatchEvent(
        new CustomEvent("range-change", {
          detail: {
            low: lowSlider.valueAsNumber,
            high: parseInt(event.target.value),
          },
        })
      );
    });
  });
});
