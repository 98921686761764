import { API_URL, API_KEY } from "./index";
import { buildTimestamp, createLineChart } from "./charts";

export const getWeeklyAverageHotelPrices = async (
  elementId,
  isDarkTheme = false
) => {
  fetch(`${API_URL}/hotel-prices/week`, {
    headers: { authorization: `Bearer ${API_KEY}` },
  })
    .then((res) => res.json())
    .then((res) => {
      const prices = res.data.prices.sort((a, b) => {
        const aDate = new Date(a.date).getTime();
        const bDate = new Date(b.date).getTime();
        return aDate - bDate;
      });

      const timestamp = buildTimestamp(
        new Date(prices[prices.length - 1].updated)
      );
      const columnLabels = prices.map((price) => price.date);
      const dataLabel = `Average hotel prices near Times Square as of ${timestamp}`;
      const data = prices.map((price) => price.price.toFixed(2));
      const barLabelMutation = (label) => `$${label}`;

      createLineChart(
        elementId,
        columnLabels,
        dataLabel,
        data,
        barLabelMutation,
        isDarkTheme,
        false,
      );
    });
};
