document.addEventListener("turbolinks:load", init);
document.addEventListener("modal:load", init);

function init() {
  document.querySelectorAll(".searchable-select").forEach((element) => {
    const getSelect = () => element.querySelector(".searchable-select__select");
    const getOptions = () =>
      element.querySelector(".searchable-select__options");
    const getInput = () => element.querySelector(".searchable-select__input");
    const getOptionRows = () =>
      element.querySelectorAll(".searchable-select__option");
    const getSelected = () =>
      element.querySelector(".searchable-select__selected");

    const activate = () => {
      getOptions().classList.add("visible");
      getInput().classList.add("visible");
      getInput().focus();
    };

    const deactivate = () => {
      setTimeout(() => {
        getOptions().classList.remove("visible");
        getInput().classList.remove("visible");
      }, 300);
    };

    // Set value initially
    getSelect().value = getSelect().getAttribute("value");

    // On activate
    getSelected().addEventListener("click", activate);

    // On Deactivate
    getInput().addEventListener("blur", deactivate);

    // On Change
    getInput().addEventListener("input", (event) => {
      getOptionRows().forEach((option) => {
        const optionName = option.textContent.toLowerCase();
        if (optionName.includes(event.target.value.toLowerCase())) {
          option.classList.remove("hidden");
        } else {
          option.classList.add("hidden");
        }
      });
    });

    // On Select
    getOptionRows().forEach((option) => {
      option.addEventListener("click", (event) => {
        const { optionValue } = event.target.dataset;
        getSelect().setAttribute("value", optionValue);
        getSelect().value = optionValue;
        getInput().value = event.target.textContent;
        element.querySelector(
          ".searchable-select__selected--text"
        ).textContent = event.target.textContent;
        deactivate();
      });
    });
  });
}
