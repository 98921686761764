import { API_URL, API_KEY } from "../util";

document.addEventListener("turbolinks:load", function() {
  // if admin analytics age
  if (document.getElementById("analytics-page")) {
    getQrScans();
    getLandingPageVisits();
    getListingModals();
    getWebsiteClicks();
  }
});

async function getQrScans() {
  const container = document.querySelector("#qr-scans");
  if (!container) return;

  const template = document.querySelector("template#counts-table");
  const table = template.content.cloneNode(true).querySelector("table");
  const title = table.querySelector("[type]");
  title.textContent = "QR Scans";

  container.appendChild(table);
  container.classList.remove("loader");

  const rowTemplate = table.querySelector("template#table-row");
  await Promise.all([
    await getResults(
      "All Scans",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "all"
    ),
    await getResults(
      "DragonFlyer",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "dragonflyer"
    ),
    await getResults(
      "TKTS Patron Services",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "entrancesurvey"
    ),
    await getResults(
      "TKTS Exit Survey",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "exitsurvey"
    ),
    await getResults(
      "Street Meet",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "streetmeet"
    ),
    await getResults(
      "Theatre Center/General Use",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "theatercenter"
    ),
    await getResults(
      "TKTS Lincoln Center",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "lincolncenterpatronservices"
    ),
    await getResults(
      "TKTS Lincoln Center Survey",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "lincolncentersurvey"
    ),
    await getResults(
      "TKTS Sign Post",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "signpost"
    ),
    await getResults(
      "TKTS Off Island Flyer",
      table,
      rowTemplate.content.cloneNode(true).querySelector("tr"),
      "off-island-flyer"
    )
  ]);

  addSortListeners();
}

async function getLandingPageVisits() {
  const container = document.querySelector("#landing-page-visits");
  if (!container) return;

  const template = document.querySelector("template#counts-table");
  const table = template.content.cloneNode(true).querySelector("table");
  const title = table.querySelector("[type]");
  title.textContent = "Landing Page Visits";

  const rowTemplate = table.querySelector("template#table-row");

  await getResults(
    "Information Services",
    table,
    rowTemplate.content.cloneNode(true).querySelector("tr"),
    "information"
  );

  await getResults(
    "Homepage",
    table,
    rowTemplate.content.cloneNode(true).querySelector("tr"),
    "landing-page"
  );

  await getResults(
    "Services",
    table,
    rowTemplate.content.cloneNode(true).querySelector("tr"),
    "services-page"
  );

  container.appendChild(table);
  container.classList.remove("loader");

  addSortListeners();
}

async function getListingModals() {
  const container = document.querySelector("#website-clicks");
  if (!container) return;

  const url = new URL(`${API_URL}/analytics/tallies/listing-modals`);
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const template = document.querySelector("template#counts-table");
  const table = template.content.cloneNode(true).querySelector("table");
  const title = table.querySelector("[type]");
  title.textContent = "Show Modal Clicks";

  const rowTemplate = table.querySelector("template#table-row");

  Object.entries(result.data).forEach(([name, tallies]) => {
    const row = rowTemplate.content.cloneNode(true).querySelector("tr");
    const td = document.createElement("td");
    td.textContent = name;
    row.appendChild(td);

    displayResults(row, tallies);

    table.querySelector("tbody").appendChild(row);
  });

  container.appendChild(table);
  container.classList.remove("loader");

  addSortListeners();
}

async function getWebsiteClicks() {
  const container = document.querySelector("#listing-modals");
  if (!container) return;

  const url = new URL(`${API_URL}/analytics/tallies/visit-show-website-clicks`);
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const template = document.querySelector("template#counts-table");
  const table = template.content.cloneNode(true).querySelector("table");
  const title = table.querySelector("[type]");
  title.textContent = "Show Website Link Clicks";

  const rowTemplate = table.querySelector("template#table-row");

  Object.entries(result.data).forEach(([name, tallies]) => {
    const row = rowTemplate.content.cloneNode(true).querySelector("tr");
    const td = document.createElement("td");
    td.textContent = name;
    row.appendChild(td);

    displayResults(row, tallies);

    table.querySelector("tbody").appendChild(row);
  });

  container.appendChild(table);
  container.classList.remove("loader");

  addSortListeners();
}

async function getResults(name, table, row, path) {
  let td = document.createElement("td");
  td.textContent = name;
  row.appendChild(td);

  const url = new URL(`${API_URL}/analytics/tallies/${path}`);
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  displayResults(row, result.data);

  table.querySelector("tbody").appendChild(row);
}

function displayResults(row, results) {
  const { total, unique } = results;

  const { format } = new Intl.NumberFormat();

  let td = document.createElement("td");
  td.textContent = format(unique.today);
  td.dataset.count = unique.today;
  row.appendChild(td);

  td = document.createElement("td");
  td.textContent = format(unique.yesterday);
  td.dataset.count = unique.yesterday;
  row.appendChild(td);

  td = document.createElement("td");
  td.textContent = format(unique.trailingWeek);
  td.dataset.count = unique.trailingWeek;
  row.appendChild(td);

  // td = document.createElement("td");
  // td.textContent = format(unique.trailingMonth);
  // td.dataset.count = unique.trailingMonth;
  // row.appendChild(td);
  //
  // td = document.createElement("td");
  // td.textContent = format(unique.ever);
  // td.dataset.count = unique.ever;
  // row.appendChild(td);

  td = document.createElement("td");
  td.textContent = format(total.today);
  td.dataset.count = total.today;
  row.appendChild(td);

  td = document.createElement("td");
  td.textContent = format(total.yesterday);
  td.dataset.count = total.yesterday;
  row.appendChild(td);

  td = document.createElement("td");
  td.textContent = format(total.trailingWeek);
  td.dataset.count = total.trailingWeek;
  row.appendChild(td);

  // td = document.createElement("td");
  // td.textContent = format(total.trailingMonth);
  // td.dataset.count = total.trailingMonth;
  // row.appendChild(td);
  //
  // td = document.createElement("td");
  // td.textContent = format(total.ever);
  // td.dataset.count = total.ever;
  // row.appendChild(td);
}

function addSortListeners() {
  document.querySelectorAll("a.sort").forEach(function(a) {
    a.removeEventListener("click", sortHandler);
    a.addEventListener("click", sortHandler);
  });
}

function sortHandler(e) {
  e.preventDefault();

  const table = e.target.closest("table");
  const tbody = table.querySelector("tbody");

  // Remove any potential sorting arrow from all other table headers
  table.querySelectorAll("a.sort").forEach(function(a) {
    a.innerHTML = a.innerHTML.replace(" ▼", "").replace(" ▲", "");

    if (a !== e.target) {
      a.dataset.order = undefined;
    }
  });

  // Set the order and arrow
  e.target.dataset.order = e.target.dataset.order === "asc" ? "desc" : "asc";
  const arrowSymbol = e.target.dataset.order === "asc" ? " ▼" : " ▲";

  e.target.innerHTML += arrowSymbol;

  // Sort the rows
  const { columnIndex } = e.target.dataset;
  const multiplier = e.target.dataset.order === "desc" ? 1 : -1;
  const rows = Array.from(tbody.querySelectorAll("tr")).sort(function(a, b) {
    const atd = a.querySelectorAll("td")[columnIndex];
    const btd = b.querySelectorAll("td")[columnIndex];
    return (atd.dataset.count - btd.dataset.count) * multiplier;
  });

  // Set the newly sorted rows
  tbody.textContent = "";
  rows.forEach(function(row) {
    tbody.appendChild(row);
  });
}
