export const API_URL = `${window.__API_URL__}`;
export let API_KEY = `${window.__API_KEY__}`;
setInterval(() => {
  fetch("/public-token")
    .then((res) => res.text())
    .then((token) => (API_KEY = token));
}, 1000 * 30);

export const debounce = (func, wait = 300) => {
  let timeout;
  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function generateIntervals(startTime, endTime, intervalMinutes = 5) {
  const intervals = [];
  let currentTime = new Date(startTime);

  while (currentTime <= endTime) {
    intervals.push(new Date(currentTime));
    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
  }

  return intervals;
}

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const formatDate = (value) => {
  const date = new Date(value);
  const dayOfWeek = DAYS[date.getDay()];
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().substring(2);
  return `${dayOfWeek} ${month}/${day}/${year}`;
};

export const sortByDate = (dates) => {
  return [...dates].sort((a, b) => {
    const aDate = new Date(a).getTime();
    const bDate = new Date(b).getTime();
    return aDate < bDate ? -1 : 1;
  });
};
