import { API_URL, API_KEY } from "./index";
import {
  createBarChart,
  // createDoughnutChart,
  createPieChart,
} from "./charts";

const getSurveyResults = async (days = 7) => {
  const result = await (
    await fetch(`${API_URL}/surveys/?days=${days}`, {
      headers: { authorization: `Bearer ${API_KEY}` },
    })
  ).json();
  return result.data.surveys;
};

export const showSurveyResults = async (
  elementIds,
  trailingDays = 7,
  isDarkTheme = false
) => {
  const {
    ageRanges,
    residences,
    showsPerYear,
    covidConcern,
    tktsDiscovery,
    isFirstShow,
  } = elementIds;
  const data = await getSurveyResults(trailingDays);

  if (ageRanges) {
    showAgeRanges(ageRanges, data, isDarkTheme);
  }

  if (residences) {
    showResidences(residences, data, isDarkTheme);
  }

  if (showsPerYear) {
    showShowsPerYear(showsPerYear, data, isDarkTheme);
  }

  if (covidConcern) {
    showCovidConcern(covidConcern, data);
  }

  if (tktsDiscovery) {
    showTktsDiscovery(tktsDiscovery, data, isDarkTheme);
  }

  if (isFirstShow) {
    showIsFirstShow(isFirstShow, data, isDarkTheme);
  }
};

const showAgeRanges = (elementId, data, isDarkTheme = false) => {
  const formatted = data.reduce((map, result) => {
    if (!map[result.age]) {
      map[result.age] = 0;
    }
    map[result.age]++;

    return map;
  }, {});

  Object.keys(formatted).forEach((key) => {
    formatted[key] = parseInt(
      ((formatted[key] / data.length) * 100).toString()
    );
  });

  const ordered = Object.keys(formatted)
    .sort()
    .reduce((obj, key) => {
      obj[key] = formatted[key];
      return obj;
    }, {});

  const columnLabels = Object.keys(ordered);
  const dataLabel = "Age ranges of TKTS patrons";
  const chartData = Object.values(ordered);

  createBarChart(
    elementId,
    columnLabels,
    dataLabel,
    chartData,
    (label) => `${label}%`,
    isDarkTheme
  );
};

const showResidences = (elementId, data, isDarkTheme = false) => {
  const formatted = data.reduce((map, result) => {
    if (!map[result.reside]) {
      map[result.reside] = 0;
    }
    map[result.reside]++;

    return map;
  }, {});

  Object.keys(formatted).forEach((key) => {
    formatted[key] = parseInt(
      ((formatted[key] / data.length) * 100).toString()
    );
  });

  const segmentLabels = Object.keys(formatted);
  const dataLabel = "Residences of TKTS patrons";
  const chartData = Object.values(formatted);

  //   createDoughnutChart(
  //     elementId,
  //     segmentLabels,
  //     dataLabel,
  //     chartData,
  //     isDarkTheme
  //   )

  createBarChart(
    elementId,
    segmentLabels,
    dataLabel,
    chartData,
    (a) => `${a}%`,
    isDarkTheme
  );
};

const showShowsPerYear = (elementId, data, isDarkTheme = false) => {
  const formatted = data.reduce((map, result) => {
    if (!map[result.showsPerYear]) {
      map[result.showsPerYear] = 0;
    }
    map[result.showsPerYear]++;

    return map;
  }, {});

  Object.keys(formatted).forEach((key) => {
    formatted[key] = parseInt(
      ((formatted[key] / data.length) * 100).toString()
    );
  });

  const segmentLabels = Object.keys(formatted);
  const dataLabel = "Shows seen per year by TKTS patrons";
  const chartData = Object.values(formatted);

  createPieChart(elementId, segmentLabels, dataLabel, chartData, isDarkTheme);
};

const showCovidConcern = (elementId, data) => {
  const element = document.getElementById(elementId);
  if (element) {
    const value =
      data.reduce((total, item) => total + item.covidConcern, 0) / data.length;

    element.innerHTML = value.toFixed(1).toString();
  }
};

const showTktsDiscovery = (elementId, data, isDarkTheme = false) => {
  const formatted = data.reduce((map, result) => {
    if (!map[result.shopTkts]) {
      map[result.shopTkts] = 0;
    }
    map[result.shopTkts]++;

    return map;
  }, {});

  Object.keys(formatted).forEach((key) => {
    formatted[key] = parseInt(
      ((formatted[key] / data.length) * 100).toString()
    );
  });

  const segmentLabels = Object.keys(formatted);
  const dataLabel = "How TKTS patrons discovered TKTS";
  const chartData = Object.values(formatted);

  createPieChart(elementId, segmentLabels, dataLabel, chartData, isDarkTheme);
};

const showIsFirstShow = (elementId, data, isDarkTheme = false) => {
  const formatted = data.reduce((map, result) => {
    if (!result.noob) return map;

    if (!map[result.noob]) {
      map[result.noob] = 0;
    }
    map[result.noob]++;

    return map;
  }, {});

  Object.keys(formatted).forEach((key) => {
    formatted[key] = parseInt(
      ((formatted[key] / data.length) * 100).toString()
    );
  });

  const labelMap = {
    yes: "First Time Broadway Ticket Buyer",
    no: "Broadway Returnee",
  };

  const segmentLabels = Object.keys(formatted).map(
    (label) => labelMap[label.toLowerCase()]
  );
  const dataLabel = "If this is a TKTS Patron's first show";
  const chartData = Object.values(formatted);

  createPieChart(
    elementId,
    segmentLabels,
    dataLabel,
    chartData,
    isDarkTheme,
    "top"
  );

  //   createBarChart(
  //     elementId,
  //     segmentLabels,
  //     dataLabel,
  //     chartData,
  //     (a: string) => `${a}%`,
  //     isDarkTheme,
  //     true
  //   )
};
