document.addEventListener("turbolinks:load", function () {
  // if information page
  if (!document.getElementById("information")) return;

  document
    .getElementById("info-board-toggle")
    ?.addEventListener("change", (e) => {
      const isShowtime = e.target.checked;

      const standardBoard = document.getElementById("standard-board");
      const showtimeBoard = document.getElementById("showtime-board");

      if (isShowtime) {
        if (showtimeBoard.classList.contains("hidden")) {
          showtimeBoard.classList.remove("hidden");
        }
        if (!standardBoard.classList.contains("hidden")) {
          standardBoard.classList.add("hidden");
        }
      } else {
        if (standardBoard.classList.contains("hidden")) {
          standardBoard.classList.remove("hidden");
        }
        if (!showtimeBoard.classList.contains("hidden")) {
          showtimeBoard.classList.add("hidden");
        }
      }
    });
});
