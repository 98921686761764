import { API_URL, API_KEY } from "../util";

document.addEventListener("turbolinks:load", function() {
  // if users-list page
  if (document.getElementById("users-list")) {
    handleFilters();
    handleNewUsersFilter();
    handleAddUserToGroup();
  }

  if (document.getElementById("users-form")) {
    document.getElementById("user_role_id")?.addEventListener("change", handleVenuesField)
  }
});

function handleVenuesField() {
  const select = document.getElementById("user_role_id")
  const roles = JSON.parse(select.dataset.roles)
  const role = roles.find(r => r.id.toString() === select.value)
  if (role.code === "box-office") {
    document.getElementById("venues").style.display = "block";
  } else {
    document.getElementById("venues").style.display = "none";
  }
}

document.addEventListener("modal:load", function() {
  // if users-list page
  if (document.getElementById("users-list")) {
    handleAddUserToGroup();
  }
});

const MAIN_FILTER_SELECTOR = ".users-list__main-filters a.users-list__filter";
const SUB_FILTER_SELECTOR = ".users-list__sub-filters a.users-list__filter";
const REMOVE_PRIMARY_SHOW_SELECTOR = ".users-list__remove-primary";
const getWrapper = () => document.getElementById("users-list");

function handleFilters() {
  const wrapper = getWrapper();

  // Handle main filter
  wrapper.querySelectorAll(MAIN_FILTER_SELECTOR).forEach(function(a) {
    a.addEventListener("click", function(e) {
      e.preventDefault();

      // First, unselect all
      wrapper
        .querySelectorAll(MAIN_FILTER_SELECTOR)
        .forEach((a) => a.classList.remove("selected"));

      // Add the class for style
      e.target.classList.add("selected");
      const filter = e.target.dataset.filter;

      // Show all rows and hide those that should not be visible
      wrapper.querySelectorAll("tr").forEach((row) => {
        if (row.closest("thead")) return;

        row.classList.remove("hidden");

        if (!filter) return;

        const isVisibleAttr = row.dataset[filter];
        const isVisible = JSON.parse(isVisibleAttr);
        if (!isVisible) row.classList.add("hidden");
      });

      // Hide the sub-filter lists and then show the applicable one
      document
        .querySelectorAll(".users-list__sub-filters")
        .forEach(function(list) {
          list.style.display = "none";
          list
            .querySelectorAll("a")
            .forEach((a) => a.classList.remove("selected"));

          if (list.dataset.filter !== filter) return;

          list.style.display = "block";
        });

      // Default to showing the "delete" button rather than other actions past it
      wrapper.querySelectorAll(".users-list__delete").forEach(function(cell) {
        cell.style.display = "table-cell";
        cell.nextElementSibling.style.display = "none";
      });

      handleNewUsersFilter();
    });
  });

  // Handle sub filters
  wrapper.querySelectorAll(SUB_FILTER_SELECTOR).forEach(function(a) {
    a.addEventListener("click", function(e) {
      e.preventDefault();

      const subFilters = e.target.closest(".users-list__sub-filters");

      // Set the "selected" class as applicable for style
      subFilters
        .querySelectorAll("a")
        .forEach((a) => a.classList.remove("selected"));

      e.target.classList.add("selected");

      // For now, sub-filters should have production ids. If not, return in error
      const productionId = e.target.dataset.productionId;
      if (!productionId) return;

      // Only get those whose subfilter attributes are true
      wrapper
        .querySelectorAll(
          "tr[data-is-primary-show-recipient='true'], tr[data-is-show-update-recipient='true']"
        )
        .forEach((row) => {
          if (row.closest("thead")) return;

          // From there, ensure we're using the correct attribute
          const matchesFitler = JSON.parse(
            row.dataset[subFilters.dataset.filter]
          );
          if (!matchesFitler) return;

          // Mark as visible
          row.classList.remove("hidden");
          const productionIds = JSON.parse(
            row.dataset[subFilters.dataset.idArray]
          );

          // Then mark as hidden if applicable
          const isVisible = productionIds.includes(parseInt(productionId));
          if (!isVisible) row.classList.add("hidden");
        });

      // Right now, "delete" should be the final action for all but primary show recipient filter.
      // If it's this filter, hide "delete" and show the following filter
      const isPrimaryShowRecipient =
        subFilters.dataset.filter === "isPrimaryShowRecipient";

      wrapper.querySelectorAll(".users-list__delete").forEach(function(cell) {
        if (isPrimaryShowRecipient) {
          cell.style.display = "none";
          cell.nextElementSibling.style.display = "table-cell";
        } else {
          cell.style.display = "table-cell";
          cell.nextElementSibling.style.display = "none";
        }
      });

      handleNewUsersFilter();
    });
  });

  // Handle primary show removals
  wrapper
    .querySelectorAll(REMOVE_PRIMARY_SHOW_SELECTOR)
    .forEach(function(cell) {
      cell.querySelector("a").addEventListener("click", function(e) {
        e.preventDefault();
        const { userId } = e.target.closest("tr[data-user-id]").dataset;
        const { productionId } = wrapper
          .querySelector('div[data-filter="isPrimaryShowRecipient"]')
          .querySelector("a.selected").dataset;

        const token = document
          .querySelector("meta[name=csrf-token]")
          .getAttribute("content");

        fetch(`/admin/primary-show`, {
          headers: {
            "X-CSRF-Token": token,
            "Content-Type": "application/json",
          },
          method: "DELETE",
          body: JSON.stringify({ userId, productionId }),
        })
          .then((res) => res.json())
          .then(({ success }) => {
            if (!success) {
              return alert(
                "Somethign went wrong. Try refreshing and trying again."
              );
            }

            // Replace the data attribute to not contian the production id
            const row = wrapper.querySelector(`tr[data-user-id='${userId}']`);
            const currentProductionIds = JSON.parse(
              row.dataset.primaryShowReportIds
            );
            const newProductionIds = currentProductionIds.filter(
              (id) => id != productionId
            );
            row.dataset.primaryShowReportIds = JSON.stringify(newProductionIds);

            // Click the selected items to refilter
            wrapper.querySelectorAll("a.selected").forEach((a) => a.click());
          });
      });
    });
}

function handleNewUsersFilter() {
  // Get the selected main filter
  const selectedMainFilter = getWrapper().querySelector(
    `${MAIN_FILTER_SELECTOR}.selected`
  );
  // Get the selected sub filter
  const selectedSubFitler = getWrapper().querySelector(
    `${SUB_FILTER_SELECTOR}.selected`
  );

  // Get the selected user ids
  const visibleRows = getWrapper().querySelectorAll("tr:not(.hidden)");
  const userIds = Array.from(visibleRows)
    .map((row) => row.dataset.userId)
    .filter(Boolean);

  // Set the href for the email link
  const link = getWrapper().querySelector(".users-list__action--email");
  const href = `/admin/email-users?user_id=${userIds.join(",")}`;
  link.href = href;

  // Modify the text in the email link
  link.innerText = `Send an email to ${selectedMainFilter.textContent.trim()}`;
  if (selectedSubFitler)
    link.innerText += ` (${selectedSubFitler.textContent.trim()})`;

  // Modify the text in the New User link
  const newUserLink = getWrapper().querySelector(".users-list__action--new");
  newUserLink.innerText = `Create a new user with source: Admin Created in ${selectedMainFilter.textContent.trim()}`;
  if (selectedSubFitler)
    newUserLink.innerText += ` (${selectedSubFitler.textContent.trim()})`;

  // Modify the query param in the New User link
  const newUserPath = new URL(newUserLink.href).pathname;
  newUserLink.href = `${newUserPath}?source=${selectedMainFilter.textContent.trim()}`;

  // Possibly hide the add user to group button
  const addToGroupButton = getWrapper().querySelector(
    ".users-list__action--add"
  );
  const { permissionId, status } = selectedMainFilter.dataset;
  const isVisible = permissionId || status;
  if (isVisible) {
    addToGroupButton.classList.remove("hidden");
  } else {
    addToGroupButton.classList.add("hidden");
  }
}

function handleAddUserToGroup() {
  getWrapper()
    .querySelector("#add-user-to-group")
    ?.addEventListener("click", async function() {
      const userId = getWrapper().querySelector("#user_id").value;
      if (!userId) return;

      const mainFilter = getWrapper().querySelector(
        `${MAIN_FILTER_SELECTOR}.selected`
      );

      const { permissionId, status, filter, idArray } = mainFilter.dataset;
      if (!permissionId && !status) return;

      const userRow = getWrapper().querySelector(`[data-user-id="${userId}"]`);

      if (permissionId) {
        const response = await fetch(`${API_URL}/users/${userId}/permission`, {
          headers: { authorization: `Bearer ${API_KEY}` },
          method: "POST",
          body: JSON.stringify({
            permissionId: parseInt(permissionId),
            creatorId: currentUserId,
          }),
        }).then((res) => res.json());

        if (response.status !== "success")
          return alert("Something went wrong. Try again later.");

        userRow.dataset[filter] = true;
      } else if (status) {
        const subFilter = getWrapper().querySelector(
          `${SUB_FILTER_SELECTOR}.selected`
        );
        const productionId = subFilter?.dataset?.productionId;
        if (!productionId) return;

        const response = await fetch(`${API_URL}/users/${userId}/production`, {
          headers: { authorization: `Bearer ${API_KEY}` },
          method: "POST",
          body: JSON.stringify({
            productionId: parseInt(productionId),
            status,
            creatorId: currentUserId,
          }),
        }).then((res) => res.json());

        if (response.status !== "success")
          return alert("Something went wrong. Try again later.");

        const productionIds = JSON.parse(userRow.dataset[idArray]);
        productionIds.push(parseInt(productionId));
        userRow.dataset[idArray] = JSON.stringify(productionIds);
      }

      getWrapper().querySelector(".modal__close").click();
      getWrapper()
        .querySelectorAll("a.selected")
        .forEach((a) => a.click());
    });
}
