import { renderChartToScreen, borderColors } from "../util/charts";
import { API_URL, sortByDate, formatDate, API_KEY } from "../util";

const getDayOfYear = () => {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff = now - start;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll("[show-pod]").forEach(async (pod) => {
    const { productionId } = pod.dataset;

    const params = new URLSearchParams(window.location.search);
    const timeframe = params.get("timeframe");
    const days =
      {
        week: 7,
        month: 30,
        quarter: 90,
        ytd: getDayOfYear(),
        year: 365,
      }[timeframe] || 7;

    const url = new URL(`${API_URL}/shows/tallies/show-pod`);
    url.searchParams.set("productionId", productionId);
    url.searchParams.set("days", days);
    const result = await fetch(url, {
      headers: {
        authorization: `Bearer ${API_KEY}`,
      },
    }).then((res) => res.json());

    const sortedDates = sortByDate(Object.keys(result.data));

    const labels = sortedDates.map((date) =>
      formatDate(date.split("-").join("/"))
    );

    const data = {
      labels,
      datasets: [
        {
          label: "Avg. Price ($)",
          data: sortedDates.map((date) => result.data[date]["price"]),
          backgroundColor: borderColors[1],
          borderColor: borderColors[1],
          borderWidth: 1,
        },
        {
          label: "Avg. Discount (%)",
          data: sortedDates.map((date) => result.data[date]["discount"]),
          backgroundColor: borderColors[0],
          borderColor: borderColors[0],
          borderWidth: 1,
        },
      ],
    };

    const config = {
      type: "line",
      data,
      options: {
        tension: 0.3,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              title: ([context]) => {
                const date = sortedDates[context.dataIndex];
                return formatDate(date.split("-").join("/"));
              },
              label: (context) => {
                return `$${parseFloat(context.formattedValue).toFixed(2)}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                weight: labels.map((label) =>
                  [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
                ),
              },
            },
          },
        },
      },
    };

    renderChartToScreen(`show-pod-${productionId}`, config);
  });
});
