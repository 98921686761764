import { getBigChartColor, renderChartToScreen } from "../util/charts";
import { formatDate, sortByDate } from "../util";

document.addEventListener("turbolinks:load", function () {
  // if production-sales-weeks
  if (document.getElementById("production-sales-weeks")) {
    ticketsSoldChart();
    salesTotalsChart();
  }

  if (document.getElementById("sales-total-page")) {
    salesTotalsChart();
  }
  if (document.getElementById("tickets-sold-page")) {
    ticketsSoldChart();
  }
});

function ticketsSoldChart() {
  const container = document.getElementById("tickets-sold-chart");
  if (!container) return;
  const salesData = JSON.parse(container.dataset.chartData);

  const dates = salesData.reduce((dates, item) => {
    return [...new Set([...dates, ...Object.keys(item.data)])];
  }, []);
  const sortedDates = sortByDate(dates);
  const formattedDates = sortedDates.map(formatDate);

  const data = {
    labels: formattedDates,
    datasets: salesData.map((item, i) => {
      return {
        label: item.name,
        data: sortedDates.map((date) => item.data[date]),
        backgroundColor: getBigChartColor(i),
        borderColor: getBigChartColor(i),
        borderWidth: 1,
        // hidden: true
      };
    }),
  };

  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
    },
  };

  renderChartToScreen(container.id, config);
}

function salesTotalsChart() {
  const container = document.getElementById("sales-total-chart");
  if (!container) return;
  const salesData = JSON.parse(container.dataset.chartData);

  const dates = salesData.reduce((dates, item) => {
    return [...new Set([...dates, ...Object.keys(item.data)])];
  }, []);
  const sortedDates = sortByDate(dates);
  const formattedDates = sortedDates.map(formatDate);

  const data = {
    labels: formattedDates,
    datasets: salesData.map((item, i) => {
      return {
        label: item.name,
        data: sortedDates.map((date) => item.data[date]),
        backgroundColor: getBigChartColor(i),
        borderColor: getBigChartColor(i),
        borderWidth: 1,
        // hidden: true
      };
    }),
  };

  const config = {
    type: "line",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
    },
  };

  renderChartToScreen(container.id, config);
}
