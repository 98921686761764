document.addEventListener("turbolinks:load", function () {
  // Click listener
  document.querySelectorAll("[data-modal-trigger]").forEach((trigger) => {
    const { modalTrigger } = trigger.dataset;

    const getModal = () =>
      document.querySelector(`[data-modal-name="${modalTrigger}"]`);

    trigger.addEventListener("click", (event) => {
      event.preventDefault();
      const modal = getModal();

      const modalTemplate = document.querySelector(
        `template[data-modal-template="${modalTrigger}"]`
      );
      const template = modalTemplate.content.cloneNode(true);

      const contentDiv = modal.querySelector("[content]");
      contentDiv.innerHTML = "";
      contentDiv.appendChild(template);

      document.body.style.setProperty("overflow-y", "hidden");

      modal.classList.add("open");

      document.dispatchEvent(new Event("modal:load"));
    });

    // Disable clicking UI things behind the modal
    getModal().addEventListener("click", (event) => event.stopPropagation());

    // Close listener
    document
      .querySelector(`[data-modal-close="${modalTrigger}"]`)
      .addEventListener("click", (event) => {
        event.preventDefault();
        const modal = getModal();

        document.body.style.setProperty("overflow-y", "scroll");

        modal.classList.remove("open");

        modal.dispatchEvent(new Event("close"));
      });
  });
});
