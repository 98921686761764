import { API_KEY, API_URL, generateIntervals } from "../util";
import { getChartColor, renderChartToScreen } from "../util/charts";

document.addEventListener("turbolinks:load", function() {
  // if homepage
  if (document.getElementById("homepage")) {
    const button = document.querySelector("#newsletter-more")

    button.removeEventListener("click", handleMoreClick)
    button.addEventListener("click", handleMoreClick)

    function handleMoreClick() {
      const container = document.querySelector(".newsletter")

      container.classList.toggle("expand")
      if (button.textContent === "More") {
        button.textContent = "Less";
      } else {
        button.textContent = "More"
      }
    }

    makeChart()

    async function makeChart() {
      setInterval(function() {
        if (
          (new Date().getMinutes() % 5 === 0) &&
          (new Date().getHours() < 20 && new Date().getHours() >= 11)
        ) {
          makeChart()
        }
      }, 1000 * 60)

      const [listRequestRes, surveyRes, adjustmentsRes] = await Promise.all([
        await (
          await fetch(`${API_URL}/analytics/list-requests?days=1`, {
            headers: { authorization: `Bearer ${API_KEY}` },
          })
        ).json(),
        await (
          await fetch(`${API_URL}/surveys/?days=1`, {
            headers: { authorization: `Bearer ${API_KEY}` },
          })
        ).json(),
        await (
          await fetch(`${API_URL}/shows/adjustments?days=1`, {
            headers: { authorization: `Bearer ${API_KEY}` },
          })
        ).json()
      ])

      const listRequests = listRequestRes.data
      const { surveys } = surveyRes.data
      const adjustments = adjustmentsRes.data

      const startTime = new Date();
      startTime.setHours(11, 0, 0, 0); // 11am EST

      let title = "Right Now at TKTS"
      const now = new Date();
      const endTime = new Date();
      if (now.getHours() > 20) { // 8pm EST
        endTime.setHours(20, 0, 0, 0); // 8pm EST
        title = `${new Date().toLocaleString(window.navigator.language, { dateStyle: "full" })} at TKTS`
      } else {
        endTime.setTime(now.getTime());
      }
      const intervals = generateIntervals(startTime, endTime);

      const empty = intervals.reduce(function(acc, interval) {
        acc[interval.getTime()] = []
        return acc
      }, {})
      const intervaledListRequests = listRequests.reduce(function(acc, lr) {
        const date = new Date(lr * 1000)

        intervals.forEach(interval => {
          if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
            acc[interval.getTime()].push(lr);
          }
        });

        return acc
      }, JSON.parse(JSON.stringify(empty)))
      const intervaledSurveys = surveys.reduce(function(acc, survey) {
        const date = new Date(survey.timestamp)

        intervals.forEach(interval => {
          if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
            acc[interval.getTime()].push(survey);
          }
        });

        return acc
      }, JSON.parse(JSON.stringify(empty)))
      const intervaledAdjustments = adjustments.reduce(function(acc, adjustment) {
        const date = new Date(adjustment * 1000)

        intervals.forEach(interval => {
          if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
            acc[interval.getTime()].push(adjustment);
          }
        });

        return acc
      }, JSON.parse(JSON.stringify(empty)))

      let totalListRequests = 0;
      let totalSurveys = 0;
      let totalAdjustments = 0;

      const configTop = {
        type: "line",
        options: {
          tension: 0.3,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                // color: "#f0f0f0",
                font: { family: "monospace" }
              },
            },
            x: {
              ticks: {
                font: { family: "monospace" }
              }
            }
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                font: { family: "monospace" }
              }
            },
            tooltip: {
              font: { family: "monospace" },
            },
            title: {
              font: { family: "monospace" },
              display: true,
              text: title,
              position: "top",
            },
          },
        },
        data: {
          labels: Object.keys(intervaledSurveys).map(d => new Date(parseInt(d)).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })),
          datasets: [
            {
              label: "List Requests",
              data: Object.values(intervaledListRequests).map(function(list) {
                totalListRequests += list.length
                return totalListRequests
              }),
              backgroundColor: getChartColor(0),
              borderColor: getChartColor(0),
              pointRadius: 0,
              borderWidth: 2,
            },
            {
              label: "Survey Responses",
              data: Object.values(intervaledSurveys).map(function(list) {
                totalSurveys += list.length
                return totalSurveys
              }),
              backgroundColor: getChartColor(1),
              borderColor: getChartColor(1),
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        }
      }

      renderChartToScreen("chart--top", configTop)

      const configBottom = {
        type: "line",
        options: {
          tension: 0.3,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                // color: "#f0f0f0",
                font: { family: "monospace" }
              },
            },
            x: {
              ticks: {
                font: { family: "monospace" }
              }
            }
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                font: { family: "monospace" }
              }
            },
            tooltip: {
              font: { family: "monospace" },
            },
            title: {
              font: { family: "monospace" },
              display: true,
              text: title,
              position: "top",
            },
          },
        },
        data: {
          labels: Object.keys(intervaledSurveys).map(d => new Date(parseInt(d)).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })),
          datasets: [
            {
              label: "Intra-Day Listing Additions + Adjustments to Price",
              data: Object.values(intervaledAdjustments).map(function(list) {
                totalAdjustments += list.length
                return totalAdjustments
              }),
              backgroundColor: getChartColor(0),
              borderColor: getChartColor(0),
              pointRadius: 0,
              borderWidth: 2,
              stepped: true
            }
          ],
        }
      }

      renderChartToScreen("chart--bottom", configBottom)
    }
  }
});

