import {
  renderChartToScreen,
  getChartColor,
  getBorderColor,
} from "../util/charts";
import { API_URL, API_KEY, sortByDate, formatDate } from "../util";

document.addEventListener("turbolinks:load", function() {
  // if about-page
  if (document.getElementById("about-page")) {
    pricesChart();
    maybeSurveyTimestamps();
  }
});

const maybeSurveyTimestamps = () => {
  if (document.getElementById("about-page")) {
    surveyTimestamps();
  }
  setTimeout(() => {
    maybeSurveyTimestamps();
  }, 1000 * 20);
};

const surveyTimestamps = async () => {
  const url = new URL(`${API_URL}/surveys/demo-timestamps`);
  url.searchParams.set("limit", 11);
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const timestamps = result.data.surveys;

  const demoList = document.querySelector(".demographics__list");
  demoList.innerHTML = "";

  timestamps.forEach((item, i) => {
    const p = document.createElement("p");
    if (!i) p.classList.add("blink");
    p.classList.add("demographics__list-item");

    const img = document.createElement("img");
    img.src = `https://flagcdn.com/w20/${item.country.toLowerCase()}.png`;
    p.appendChild(img);

    const spacer = document.createElement("span");
    spacer.classList.add("demographics__list-item--spacer");
    p.appendChild(spacer);

    // The parsing of this string assumes it comes back in eastern
    const [datePiece, time, _adj, _tz] = item.timestamp.split(" ");
    const [yPiece, mPiece, dPiece] = datePiece.split("-");
    // const safariFormattedDatePiece = [mPiece, dPiece, yPiece].join("/");
    // const safariFormattedDate = `${safariFormattedDatePiece} ${time}`;

    const dateString = [mPiece, dPiece, yPiece].join("/");

    // const date = new Date(safariFormattedDate);
    // let m = (date.getMonth() + 1).toString();
    // if (m.length === 1) m = `0${m}`;
    // let d = date.getDate().toString();
    // if (d.length === 1) d = `0${d}`;
    // const y = date.getFullYear().toString().slice(2);
    let ampm = "AM";
    // let hour = date.getHours();
    let [hour, min] = time.split(":").map(min => parseInt(min))
    if (!hour) hour = 12;
    if (hour >= 12) ampm = "PM";
    if (hour > 12) hour -= 12;
    hour = hour.toString();
    if (hour.length === 1) hour = `0${hour}`;
    // let min = date.getMinutes().toString();
    min = min.toString();
    if (min.length === 1) min = `0${min}`;
    // const dateString = `${m}/${d}/${y}`;

    const dateSpan = document.createElement("span");
    dateSpan.innerText = `Survey: ${dateString}`;
    p.appendChild(dateSpan);

    const otherSpacer = document.createElement("span");
    otherSpacer.classList.add("demographics__list-item--spacer");
    p.appendChild(otherSpacer);

    const timeSpan = document.createElement("span");
    timeSpan.innerText = `${hour}:${min} ${ampm}`;
    p.appendChild(timeSpan);

    demoList.appendChild(p);
  });
};

const pricesChart = async () => {
  const url = new URL(`${API_URL}/shows/tallies/price-ranges`);
  url.searchParams.set("days", 4);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const priceRanges = result.data.priceRanges;

  const sortedDates = sortByDate(Object.keys(priceRanges)).filter(
    (_, i) => i > Object.keys(priceRanges).length - 4
  );

  const labels = sortedDates.map((date) =>
    formatDate(date.split("-").join("/"))
  );
  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "Range",
        data: sortedDates.map((date) => {
          const info = priceRanges[date]["all"];
          return [info?.low ?? 0, info?.high ?? 0];
        }),
        backgroundColor: getChartColor(0),
        borderColor: getChartColor(0),
        borderWidth: 1,
      },
      // Averages
      {
        type: "line",
        label: "Average",
        data: sortedDates.map((date) => {
          const info = priceRanges[date]["all"];
          return info?.averageListed || null;
        }),
        backgroundColor: getBorderColor(0),
        borderColor: getBorderColor(0),
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "bar",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              family: "monospace",
            },
          },
        },
        title: {
          display: false,
          text: "Broadway Range and Average at TKTS",
          position: "top",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const date = sortedDates[context.dataIndex];
              return formatDate(date.split("-").join("/"));
            },
            label: (context) => {
              if (Array.isArray(context.raw)) {
                const [low, high] = context.raw;
                return `$${parseFloat(low).toFixed(2)} - ${parseFloat(
                  high
                ).toFixed(2)}`;
              }

              return `$${parseFloat(context.formattedValue).toFixed(2)}`;
            },
          },
        },
      },
      scales: {
        y: { ticks: { font: { family: "monospace" } } },
        x: {
          ticks: {
            font: {
              family: "monospace",
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("price-range-chart", config);
};
